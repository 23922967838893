<template>
  <div id="workshop">
    <div>
      <p>Directly following the <a href="/2022">VIZBI 2022</a> conference, this online-only workshop brings together experts in science communication and visualization for a focused discussion on reducing barriers to understanding COVID. By drawing on lessons learned, the workshop aims to develop a white paper with recommendations for how the complex data and science underlying COVID can be more effectively communicated to a broad audience. <Email to="vizbi-workshop" show="Contact us" /> if you are interested to attend.</p>
      <figure style="margin: auto; text-align: left; font-size: 15px;">
        <img
          id="hero"
          :src="require(`@/assets/${img}`)"
          style="width: 65%; min-width: 320px;"
        >
      </figure>
      <!-- <HeroImage :src="'years/' + this.$route.params.year + '/Workshop/img/Medium.jpeg'" /> -->
      <h4>Preliminary program:</h4>
      <table style="text-align: left; width: 100%;">
        <colgroup>
          <col span="1" style="width: 25%;">
          <col span="1" style="width: 75%;">
        </colgroup>
        <tr>
          <td>Friday, March 18 (6pm-8pm PDT)</td>
          <td>Keynotes &amp; VIZBI cross-over event</td>
        </tr>
        <tr>
          <td>Saturday, March 19 (8am-12pm PDT)</td>
          <td>Presentations</td>
        </tr>
        <tr>
          <td>Sunday, March 20 (8am-12pm PDT)</td>
          <td>Breakout discussions</td>
        </tr>
      </table>
      <h4>Organizers:</h4>
      <ul>
        <li>Helen Berman (Rutgers U., USC & UCSF, USA)</li>
        <li>Seán O’Donoghue (Garvan, CSIRO, UNSW, Australia)</li>
        <li>Jim Procter (University of Dundee, UK)</li>
        <li>Carl Kesselman (USC, USA)</li>
        <li>Ruth West (University of North Texas, USA)</li>
      </ul>
      <h4>Image credits:</h4>
      <ul>
        <li>Spike protein: David Goodsell (RCSB PDB, USA)</li>
        <li><a href="https://commons.wikimedia.org/wiki/File:Mercator_Blank_Map_World.png">Mercator map:</a> Wikimedia, Creative Commons 3.0.</li>
      </ul>
    </div>
  </div>
</template>

<script>
  import Email from '@/components/Email.vue';
  export default({
    name: 'Index',
    components: { Email },
    data () {
      return {
         img: 'years/' + this.$route.params.year + '/Workshop/img/Medium.jpeg'
       };
     }
  });
</script>

<style scoped>
th {
  padding-right: 12px;
  color: #999;
  font-size: 80%;
  font-weight: 900;
  letter-spacing: 0.2em;
  text-transform: uppercase;
}

table {
  border-collapse: collapse;
  margin-bottom: 1em;
  width: 100%;
}

tr {
  vertical-align: top;
}

td, th {
  padding: 6px;
}

tr:nth-child(even) {
  background: #EEE;
}

p, ol, ul {
  line-height: 1.5em;
  min-width: 150px;
}
</style>
